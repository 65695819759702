@import url(https://fonts.googleapis.com/css?family=Baloo+2&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:ital,wght@0,400;0,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,300&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/3.1.0/css/font-awesome.min.css);
html,
body {
  overflow-x: hidden;
}

body {
  margin: 0;
  position: relative;
  background-color: #f3f3f3;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select {
  height: calc(2.4em + 0.5rem + 2px);
  display: block;
  font: 400 1rem Open Sans;
  color: #666;
  border: 1px solid LightGray;
  text-align: center;
  padding: 0.75rem;
  margin: 0 1.5rem;
  flex: 0 0 auto;
}

select:hover {
  background: #f3f3f3;
}

select:focus {
  border: 1.5px solid #ed1968;
  outline: none;
}

textarea {
  display: block;
  border: 1px solid LightGray;
  font: 400 1.2rem Open Sans;
  width: 80%;
  text-align: justify;
  padding: 1rem 0.75rem 0;
  margin: 1rem 1.5rem;
  flex: 0 0 auto;
  outline: none;
}

textarea:focus {
  border: 2px solid #ed1968;
}

/* input:focus {
  border-color: #ed1964 !important;
  outline: none;
  border-style: solid;
  border-width: 2px;
} */

.react-datepicker__input-container input:focus {
  border-color: #ed1964 !important;
  outline: none;
  border-style: solid;
  border-width: 2px;
}

.PhoneInputInput {
  height: 2rem;
  width: 90%;
}

.PhoneInputInput:focus {
  border: 2px solid #ed1964;
  outline: none;
}

.PhoneInputCountry {
  margin-left: 1.5rem;
}

.icon-oven::before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABJCAMAAACpb5DQAAAACXBIWXMAAAsSAAALEgHS3X78AAAAM1BMVEUAAACqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqoNNXyEAAAAEHRSTlMAECAwQFBgcICPn6+/z9/vIxqCigAAAf1JREFUWMPtmN2O3SAMhMfgAEkwnvd/2l6cn902UQ8kXKyqzhUR0ScbMRY2AADQclmKt6Q4rV6U0Ys8OLG9l1ckxVsEgOB2AwMAYh4A1LscQKwCifH5GfV5bqrxtQh9pMiEfX9SK0mLQDSSVYDoJNc+0r6D6bHcXBHMALMA9Q3BN0Fm6QIlgs98mAAEqjI8NrILgNW6QErwedQPIFX52JBSAWBh33ETrx89A1DGSAWQHckFwN4XEb5AhVmTV6B60swCabboymUUhOLkJoBspBcAsZKeMAy6px8OysYh1XQKEqMNVbS1cZcTkPkymk/mfgRlLuMnU14G+wYyu1KIfDuAOm3+h2qdBFpngcp/0DxQNJ0CKq4zIoq2y4zUcoeLO0ChHsORbRkG5XYs+4uX8YjWw/NBdosz7pG2MuNCynoSzgWQtnWGRWRtOsNr2laZYdrSdIb7o60yw/2fXNsJ+uzaPlA5ca2kYdCZaw8XqgOU29JxoT6CQq1n4Ryan08gbRlT3C+hpxhdcX/Y6xT355NUL4BCrWGG+8/K9xXQ8jeb/MOvkZM35Pt9OiQ7gjYP4xxlPoACx/v/6E2OLURiS0OoUNzjWXekjYP65r+vLhsAYh7pjlL8vcvWGX2f8j2JuKfE92zknvb927TmhiLTvPnRvInWtBnbvKnfnDnkL485LUzzEH9sAAAAAElFTkSuQmCC);
}

.icon-fridge::before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEkAAABVBAMAAAD3SdQPAAAACXBIWXMAAAsSAAALEgHS3X78AAAALVBMVEUAAACqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqo3wCjHAAAADnRSTlMAECAwQFBggJ+vv8/f720RUa8AAADMSURBVEjHY2BgLScAShkYGJj27VqFCl6h8fdtYmCQ3syABh6g8Zl3CzDkKhBSxaCbwDCDgaAqjgkMywmrYl8wqmpU1aiqIamK7x0cQFQhBF4hqXKAafODqoKbU0UFVWzgAm4xAVUs4MKyCEUVvNDch8/Gvjd9b8pr3tW968Onyu+V3ysGrnd87/wGmaq3+0Cq1uFX5V5eXsbABgwgvKraYXUO3pAgLlRpmyZorYqodD9aToyqGlU1qgqfKuLa0cS1yYlr3xPXVyCq3wEAZMdwKwK1QVoAAAAASUVORK5CYII=);
}

.icon-pantry::before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEkAAABNCAMAAADdPLn4AAAACXBIWXMAAAsSAAALEgHS3X78AAAAM1BMVEUAAACqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqoNNXyEAAAAEHRSTlMAECAwQFBgcICPn6+/z9/vIxqCigAAAcBJREFUWMPtmNGSgyAMRQHRgqDk/792RSOJFtxi+9Cd5c50tKkeCVwiVIii9C7xrmDX+yRsTCO9TtJXqiLBlepIV9d9PSmX+03SaTjeIGV+vTd2v5Iu7FRJ+lx2jdRIjdRIf5+Ue7fkzm+9g2vl4TPyotOfUSeavkjq3pisvjKHkNl8Uk0y24eT/PZppH9DmiJpPITc8tVMtSAJvRCPcIiFYdlEgaokDbEaK+A7rH6FhEclybk1RZ7euCZm58rJGpOLLVMsNLDDyxrxybOlkcPQ6Ov6Gx9sqM8DWkLDobB0ZpPzUaM5VZ0hSDaE2N8Y4u0U/QyzR0aUn8Hrs3PQoPsZJWXJUhbc2RTaHzoypC/JiZMhNxAo1/uGRSX5KO2xUprkMl0YRhvU0+3xtu5w5BtiXxhFST3Zw9M+mu//MFFFzTyJZhmf8Hg5J2GJ0MXlTZcMzUl428Ams7dPVxX+EMiReAjPLwpMItmXSOXsdHIxr5VlkizOZaoXnITty5CwzGRcQDWMk0yZpMDmXwGzrCQt5ctkWuRCJ2pJC2o69ZV6BAY6rn89rVN2kbeUA5g8KUAwknu0YhErtWEa9Fevx38AOyoxGPsBrnAAAAAASUVORK5CYII=);
}

.icon-range::before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEkAAAAxCAMAAACh3HRCAAAACXBIWXMAAAsSAAALEgHS3X78AAAAM1BMVEUAAACqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqoNNXyEAAAAEHRSTlMAECAwQFBgcICPn6+/z9/vIxqCigAAAVhJREFUSMftlsGShCAMRBuDiAES//9r92BtjbMDDkGO2xeqLHxikiYBmiL/KYcBsXD8q6zBDgql9n1S+6nyWn2cvJ3kQZXvxyFS9v+kHhJPI8VppJrmkfbVbJaj7rCiZARprL6ySbChvBKClvxXkglBDCYmvYvrXlw/6P4W4l7U8vU649QFcmX7uqdwF4jnbOoCdR088ZysgMukBHN/qYCEptQcgBvfmHxwhwpWmze66wCoEVcaAFVzTbpiRB/1RyNDSM0TyygIcGU32r+NkmB17XffPARdspWKf6jtrCDWFJ+qKDUr3uobAZKpIpfTCMvZxdyrmRVq9f+GObQIAbuev3O8purGuHXn/S3Di0MQkC4gHSPFCOD4Xfx+GWkupK2elbcobgnwgpABUpA4LIf7IDXy6989lllXIAtrAFj2a5xsufN+uSzk6ZK7efU0pcbzaRfyz+WAH9YGFFPq/GMgAAAAAElFTkSuQmCC);
}

.icon-skirts::before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEYAAABDCAMAAAAWPYMFAAAACXBIWXMAAAsSAAALEgHS3X78AAAAM1BMVEUAAACqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqoNNXyEAAAAEHRSTlMAECAwQFBgcICPn6+/z9/vIxqCigAAAwJJREFUWMOtl0tiwzAIRMH6IQRi7n/aLuK2SWO3Slz2jMUbEDLR5Ui5yIX0nJsMC8CtvZ7NOYuYAZimoqJipuvpWy7SzQG4denSb1puveWl9CpqE4CZiIjcKpmmUjMvkNtLDzORKrtYmErN2yvpQ1ppe/o0lZbTAngR+zpuaTuJ1a9v9+BrqV/pY+nre3TgSvpn2IRfSCeiLTchGwHN+b0xKKIGhAlZtHi9l3MVc2Ca1NsJrJOOV0ahdQvAhpR7AiYVsYZgr0DlwH+bnuJ3NLnIuFXQTrvfJtOU0wrEJuDjE8G54UIadlhBAKay1gTWTQv43sRhQFhvCyPwLYNKBYWIc5XbDP5ZwZGMUprwvYKS3rxRTdJM8LZwA/0q4zMxMl0MC6Y+L28ZE8qQ6zJdDNt1GfR8vSYyI7F/kJEU/3Kaqf/BJhKF/IfhFcHXZcS3a8fZqpCFMvvbdLaqEy5kRsnV9Q0JLjoxtfBueCV5dTi5dMfUut3WDZlHpRrT3pUQQ3QyVJJIaZVOFkeMthERpTaA0dK+7iJRRV+RMMQtjVIbAZP8ZfiYicSHr0tsVQPey8OEO5M6Z/yy8tsATBIREVedmL3w87pTZXakM4mvwz+w/dnFbErsR5Y/1J/7N9uj28+F2Cv9kLm1517/PZjzdZeiUr1z/FOC78D8tcOVUlTKgWOJgEtemfA8E9UY9o2Qn/QW1p0nar6Z37nwksTWRtwMd+YZuwSXPk9cPeoH8RiVyYRUiS2Ciei8MY6i9BladjaqxK4c/U9XH8a8asyevw1XYq9UA74qQVsdcHl4iQ5Ks1INw+qjujnsZzebpFlII+UlmdwnRn120MZMpM40sHD3xifSg3XH7kwC/InjDumTTGN3JrWC33H8boCNcCZVqmcyqU+cXhCPt18jnocyRSOOkP4MBbNV2vxZhuvAGdIndGHeaYv+w/CtOWZffyZvA8JeSfCIY72nP1tqolP9Mrxo/I30sK863Dt2HFhBemKqh2Frtoz0LATA7Nc0bn9i6f3kD43/K9A5j1uBAAAAAElFTkSuQmCC);
}

.icon-blinds::before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEkAAAA/CAMAAACb1hUyAAAACXBIWXMAAAsSAAALEgHS3X78AAAAM1BMVEUAAACqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqoNNXyEAAAAEHRSTlMAECAwQFBgcICPn6+/z9/vIxqCigAAASZJREFUWMPtmMGOgzAMRIfUGJMOjv//a/fAbgtdqVLAh+0Kn6OnBGWeHSCWUwJrzKhmMCKjaLBFMmoxWOSUvW6xo959l4t0kZJIcifZvCe0vmZfd5yRQTNz9niEbmZWw58s9aD0n05nAECZm9sAYJg8ajlllMFasyHRBVZT/FT/hp/erb5In0gqczt4j5oNW04Nn0Rk6brv6+o1c99q4o8YZu1ygWzzCyiDIzJckBS7EMSU4oKQxD3tukVal7pIn0iS++GLRHkZC8aDexI+WY+xANrlAtGHSygAJg/Xk6GbPHgD03owmfIoc8O8fVp1zXT71Xp1zn9AKgd7sJQ0F+xmcGFQj55OPar8coH0kG4FOxkUj6hnU8cIQiLlb8gSiaSS9K/HvgCq8VHRWTRVRQAAAABJRU5ErkJggg==);
}

.icon-window::before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEkAAABBCAMAAACq/nmDAAAACXBIWXMAAAsSAAALEgHS3X78AAAAM1BMVEUAAACqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqoNNXyEAAAAEHRSTlMAECAwQFBgcICPn6+/z9/vIxqCigAAAQRJREFUWMPt2OGqwyAMBeCk1ladiXn/p70wBmWtbqse2Lj0/JIiH7GVKqElYLJQ1lyLlm0slt9GM+VAteS4jWejtwlNyTxI8sYgKQqBJI0gaTIHkoISSHpa3IjknhY3IiUhjMS2gKRQGCNx2T3qlvYldUuHkrqluC+pV3K7D9cvSSaMFMuEkfxxbX2SK4kgEqswRGKpQucllsrb7pGcSh06K/lSX9oJyR5JzVmfSmm+x+ZhKRyP7zGpcT/okG42lk0KGVXTqijpxfTTEoMkam+oL0oeJbW35iVd0iX9vqTrP/7TOZt+7mxZC0rCncG3hJIEdlcB3MT00c+RZh/nw14PrP/0B5Y1I2zxgt4dAAAAAElFTkSuQmCC);
}

.icon-drawers::before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEkAAABMCAMAAAAWYGpdAAAACXBIWXMAAAsSAAALEgHS3X78AAAAM1BMVEUAAACqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqoNNXyEAAAAEHRSTlMAECAwQFBgcICPn6+/z9/vIxqCigAAAU1JREFUWMPt2MtywyAMBVDx8AskuP//tV00Ji62mRpYtBO0tDLHCjLMIKJD6GWti5l+xhID1wVEHQtirFQZWvhYkBiqDgPdoSAiIgq2R0FERGy7FLRLc4RfW9vPlsjhO2JF9yW1ny2tLwhc86dsdEkKTRLNSBLaJAvTTbJDGtKQhvRvpNoY0pA+R7LljQWypVieSL/ewZ8lzYsqSFm2JG1AULeSB+S30vsTuZLybEkKQLrNnKU8W5KM5/l+nQzzNL6nRmkp7nWLi2fT+1rmas+neL6Xjd79GUmtq7mX8myS4lliIN6fT5Jld0lD9zmflDB1qUl5UXSxTltpnTZ3XicTRBF16N0cvaIekmB7/XJqkiZgP6w9N0kc07rpKMW5EIpZiYcGaMfMHOXxwIhZEMzNdOzRhWXvfrs07bOnTHIP52oOWC5fsT0frWUj2i8bZWN4VKRUnQAAAABJRU5ErkJggg==);
}

.icon-stove::before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABACAMAAACOYMEYAAAACXBIWXMAAAsSAAALEgHS3X78AAAAM1BMVEUAAACqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqoNNXyEAAAAEHRSTlMAECAwQFBgcICPn6+/z9/vIxqCigAAAp9JREFUWMPVWNu2ozAIBYMRYwj8/9fOQ1sVoran7Vkzw1vLyjbhsjcJAABksQ9MMtwsiXKCty2xSgIASLogfGS4aAIAWeBjWwQgK34OhJpBGL5gLGAxzkNm5nH4YcQNzP+TxbTWalbpcMVQmpmZcPhSAMJq5bbDsVrpg4fFGhMRzc34Aihp2w46qkSk1DSvUfFuB4TifElDYWDb+5PKGdAcKoEsu9+1OX9SPgYawjqAWT1uiD/bcAg0ty5FDnqp0d/4EEjmvvTLPgxxwzDLIZD1lcN1f7KuHMheBRrtcNXPgehbQCzvALWpD/auJNHG7jvtEKjIEc1sJqVL/3wI1BUcsCv1bKHj80lBxhbwPQAAImG/5aTXBt+lGBZC2q8ElP13I43UzZekdTSish6GWkvnxJaaMt6Z0KQntiRWRgTAXK3iBUMCslqduYi1fEi1D1Fe6JJqAQBGrnVhOpceIuq9B0Dv2a8CDUR0rr44MvOIz4G4+VklyppZrWKP5J4CJdE5AcBYQnofEnVLJrK2dAW0qzjqZQ3GTTRRdLgA2gtbJ2u+uUIDeSB2X+nlx5FBcmLggZrXkRoIKJBBWc6AhjDiZA2jyxDo6AwosnL4fen+S0D4ItAQdGLyw0CUETcr+KwFnWgha36KQJdED5Rd5UxRNfy85KepUNlV0y7b3XQy79zZDydxGBWldT+lb9pi967H5XIYBcDFaiaiqfX7uXWJlommYjo+4yMqamatnEzsOFW1tmT8Lzn7XSD+0CYiIjKw+qK165vtvx9snHayn3ja6uXUcQxUzVa2ILNttqlma3c5xwoUhM7M6nppMVv54NTxuIqGy/GbQCzxuj7vjjaomTx1rHN0eEBI09aumMcXHPcHhO89aXztkeVLzz5/AEKpLbd1wt9oAAAAAElFTkSuQmCC);
}

.icon-linen::before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEkAAABCCAMAAAAsagstAAAACXBIWXMAAAsSAAALEgHS3X78AAAANlBMVEUAAACqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqVlZWqqqoEVSxhAAAAEHRSTlMAECAwQFBgcICPn6+/z9/vIxqCigAAAdxJREFUWMPt19tyhCAMBuA/gCKHkPL+L9sL3a2ugIpetDPNFa6Zb1wJQQAAUKYemDPsVA2r8AqfOdZCkgaghesSi16g6T0qBPkEIDk0wqVlkMZWGokBMjVT8jLIppWGaIDczMC/9PclaUvpvBRCK2vIChDbSrGyDJTE+qJyeQQwZneUMlOuuoBjmP+6CYcpvzLIxvthCSDOTwQTbH4mLKI8I0XEycSnJMBK8vERCRTyRGN4QAJMYgMMXm5LIJcdATAu3ZQAk9K87+mJ70kgl18XysY7EmASv7djGoP0SyCf19cXZuBTAgbhzVeCPjkDewkUPlutttwlAYNE9fGTsqFHgooy7PvY2HxpZQmwEkrfOYNPVyVoruyCtRmoSsA0r55CqNIMNKRNme6wXeNoSevVU7q7nYGmVKyH7f2fl3YggYKM7U1OOz4lVevhcwa+jiUolsN9n/jEMzXr4Q2xOyVBN+phhmg6J7XrgZgJZ6VWPRAz4YJUaFuvgxgTLkm1evBCuCpB8b5t+eVEd00q1MMLuixBczIl6Lq0rYcfqENat631qbdHAoVsAWBcH5+7JGCQOJjVyaBfAnnJvOkQvVKhPv6ls1J4RnJxW1/doWUEvPjpbnjxAGCm+2GAb4VjX5KCe3oxAAAAAElFTkSuQmCC);
}

.icon-washing::before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEoAAABLCAMAAADgUuHmAAAACXBIWXMAAAsSAAALEgHS3X78AAAA81BMVEX///+qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq3t7eqqqqqqqq1tbWqqqrw8PCqqqqqqqqqqqqqqqqqqqqqqqqzs7Oqqqq9vb2qqqqqqqq5ubmqqqqqqqq3t7eqqqqqqqqzs7OxsbGsrKytra2qqqqqqqqqqqqqqqqrq6uqqqqqqqqqqqqrq6usrKytra2urq6vr6+wsLCxsbGysrKzs7O0tLS2tra3t7e6urq8vLy9vb2/v7/FxcXGxsbIyMjKysrLy8vNzc3Ozs7Pz8/R0dHT09PW1tbX19fa2trb29ve3t7g4ODj4+Pn5+fs7Ozw8PD4pb55AAAALHRSTlMAECAwPUBQZmpwcHR5fYCAiIuQnqCyvL7IydDS09jZ4OLi6Ovt7vb4+fz9/g8GjvoAAAG1SURBVFjD7ZdZU8IwGEUvICqKK+C+IyJ6bQuKoIg7uJSS/v9f44Myxk512iQPKJ73nkm+3OROgQ/SqiDIPlXZCZgmaT+7KjzbnP6qyh/3fDV6R/mAir4q/H2qvhMB+/w1gko06hEge6Y22DtpGJtV3Q5VTVRim4TthKow148b8xqvw1XrJ7Ev32X4CabJ25jL8r7N1cPfvjgLvFA0XQRVyQprdRVqPEgGwjC2sa3GxhiGnKki9SnOAMjSDFmgRMfzdfEclgFS3+T7HgmQvglGUnUZKwIt8YPqKl6cbGFog67DlqlZubSMjX3wOQks7+qYuvKqMOe7yjxavJFUK5btKGKTZ9IJzuu8LOc3cq6MPDKDE/T++nV+MmB6IoEdsuPq0iFLQNpQ44wDSBXW9Cmkh7fol4yMagnAoqGx54AyG0I7VqL5XvTCQETFSLazUPk5CVedxopA1ftB1W1Gp2HR8gzNSpwN/kL0x96X21lPJWRVYUtHdceqXPSe8jv80iIfP1WJPa0H4UoKQ46sNVVpuXJEy2wKYxdH/Bd9xKJfJdv3urTJTSBVNlI4hykAicysPpkE8Aass/S7xMXkswAAAABJRU5ErkJggg==);
}

.icon-clothes::before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEgAAABACAMAAACOYMEYAAAACXBIWXMAAAsSAAALEgHS3X78AAAAM1BMVEX///+qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqpfsHZBAAAAEHRSTlMAECAwQFBgcICQoLDA0ODwVOCoyAAAAfFJREFUWMOt2NuWgyAMBdADBOQq+f+vnYdqRWpHashbXXWvNoQYBK5DFf4/isJIqMKFiIho6YHX5TIoRea8kR3kX5czcxpzdgjxDOk3xPHW8dxAurZOwAHdSo5baPu4ZQgtxG7AOSDQ+zdFdYb+lQz3EJQvzFwTHZc2iOm7Uz+hi9ihar584UjtGPRNagp6ELouzHZjjEKXkuUH0GnpTPiomWHo5Fi7Van7NdnnUrIpxvPy13QDpXq5aC50hUT+BvKmd2yIZ1UXZodbCI65NHfSApV0VwQOAxDcaeE9ALtctJJ7qLvBAskB0GREkFpzKYCJi40rCSAoor0gTSUBBABIr7T5VQrlvamZORAdHe8hVLe/xloIxaIAULNTH0K6rsGndus8hKATM2cDMSTfIlOh5W4YuhmVvNq6Rg2WBBHqq5/Er0/L0VBrBqBuZoqRIDYAMeRRHUBM8qgO0DwjDIB1glMBIEyAcj85PA3/mqQXYard1g9LEC6+2yooFCEU8z5eKRm0+v24YUWOfj8ycpiSIsCXKSkCSJak9eiWok6imnNEjgLINn2oGR5+j5DbE5F+DhXfdbgJKQJSnJIiYKlTUnSasEQpAuoyJUVASlNSBLj6cJfEbp+qmtSznW8/Tter/71Zp4u3ETr+/oBrXgX8Ae0QPji/4/D9AAAAAElFTkSuQmCC);
}

.icon-ironing::before {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEkAAAA5CAMAAABNj/YvAAAACXBIWXMAAAsSAAALEgHS3X78AAAAM1BMVEUAAACqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqoNNXyEAAAAEHRSTlMAECAwQFBgcICPn6+/z9/vIxqCigAAAd5JREFUWMPtWEm2gzAMc4YGB5xY9z/tXzCGQgmE5demw2tVR1ZkvxJtECTQK/gg4/MKU8425zeIAgIFvHG+IRNRHtqJDCIRRZgXDueIyL1wvF6JiEj79s4Nq1qNMnVERNQ1C+Xhi8fnmGuZa3sOxvQE/M903P+OmVkWJuHfCN7bQyKnGDEz1SFJz51324oUEBHJC1OWLTKKl6pSMmaJwU0+ght1msHH+o0QISJy/sM8SF6+MwQihozeXnTw10wLbDdMXB11UFPV04LJel8UldiQVWj80SnZMalI2gnFHzsl3AVKpg3DsGse2bi2BoDKrne7mgCkkuB45Cl2WcDfNdWlqsu7T34z1aaOSUAy50x6Y0r3QHaVfrqMT6y/3MREXtc708ZELgG9mZjKGyx3M0tm3cPOaPH+SAZ00d30QHJPozQAGlZnDA0D1SkQiyePYcdSAtC8wJgBSHEr2HNEAEj2hY3RZqB5UZjN3iz3YgSTAGncqSZzttmyuDDEgD7fzzaXeAzlp5baBfpXKD8Lu6NQfhbA5+/dHQpndd4fVOfD8MKO530q+lmxb/7wzsZjlxJd+bmvzJiKO7YJ5dNjMXPNvfcKxK/Vb4txK6q4EzYdrKPldBURVyemiHz9tfAH2sYma1j6cK0AAAAASUVORK5CYII=);
}

.icon-bookshelf::before {
  content: url(/static/media/bookshelf.244f3467.svg);
}

.icon-frames::before {
  content: url(/static/media/frames.78e9f1f1.svg);
}

.icon-balcony::before {
  content: url(/static/media/balcony.5ec8e86d.svg);
}

.icon-flyscreens::before {
  content: url(/static/media/flyscreens.7c6e5ed4.svg);
}

.icon-pathways::before {
  content: url(/static/media/pathways.850bb974.svg);
}

.icon-rugs::before {
  content: url(/static/media/rugs.5c469643.svg);
}

